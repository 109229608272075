import React from 'react';
import PropTypes from "prop-types"
import PriceButton from '../PriceButton';
import AbstractPriceWidget from '../AbstractPriceWidget';

import phoneLogo from '../../../images/PriceCalculator/107 Smartphone Alarm AA.svg';
import dontKnowLogo from '../../../images/PriceCalculator/000 Weiss nicht AA.svg';
import noLogo from '../../../images/PriceCalculator/000 Nein AA.svg';

export class PriceWidgetAlarmSmartphone extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        let { smartphone } = this.prices;
        this.progress = 85;
        this.cssClass = 'smartphone';
        this.setEventName('Smartphone');
        this.question = 'Möchten Sie gerne per Smartphone App auf Ihre Alarmanlage zugreifen können?';
        this.nextStep = 'alarmCrossSelling';
        this.updated([{ key: 'showSkip', value: false }]);
        if (smartphone !== null) {
            this.updated([
                { key: 'nextButtonStep', value: this.nextStep },
                { key: 'showNextButton', value: true }
            ]);
        }
    }

    elementButtons() {
        let { smartphone } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Ja"
                    logo={phoneLogo}
                    content="yes"
                    active={smartphone === 'Ja'}
                    onclick={() => { this.update('Ja'); }} /></div>
                <div className="column"><PriceButton
                    title="Nein"
                    logo={noLogo}
                    content="no"
                    active={smartphone === 'Nein'}
                    onclick={() => { this.update('Nein'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo={dontKnowLogo}
                    content="dont_know"
                    active={smartphone === 'Weiß ich nicht'}
                    onclick={() => { this.update('Weiß ich nicht'); }} /></div>
            </div>
        );
    }

    update(smartphone) {
        this.updated([
            { key: 'smartphone', value: smartphone },
            { key: 'contentWidgetStep', value: this.nextStep }
        ]);
    }
}

PriceWidgetAlarmSmartphone.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetAlarmSmartphone;
