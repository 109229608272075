import React from 'react';
import PropTypes from "prop-types";
import PriceButton from '../PriceButton';
import AbstractPriceWidget from '../AbstractPriceWidget';

import oneFloorLogo from '../../../images/PriceCalculator/103 1 Etage AA.svg';
import twoFloorLogo from '../../../images/PriceCalculator/103 2 Etagen AA.svg';
import threeMoreFloorLogo from '../../../images/PriceCalculator/103 3 Etagen oder mehr AA.svg';


export class PriceWidgetAlarmFloors extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 45;
        this.cssClass = 'floors';
        this.setEventName('Floors');

        let objectTypeWidget = this.props.prices.objectTypeWidget;
        this.multipleFloors = (objectTypeWidget === 'house' || objectTypeWidget === 'business') ? true : false

        if (this.multipleFloors) {
            this.question = 'Wie viele Etagen hat Ihr ' + (objectTypeWidget === 'house' ? 'Haus?' : 'Gewerbe?');
        } else {
            this.question = 'In welcher Etage befindet sich ' +  (objectTypeWidget === 'office' ? 'Ihr Büro?' : 'Ihre Wohnung?');
        }

        if (this.prices.floors !== null) {
            this.updated([
                { key: 'nextButtonStep', value: 'alarmWindows' },
                { key: 'showNextButton', value: true }
            ]);
        }
    }

    elementButtons() {
        let { floors } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title = {this.multipleFloors ? "1 Etage" : "Erdgeschoss"}
                    logo={oneFloorLogo}
                    content="one_floor"
                    active={floors === '1'}
                    onclick={() => { this.update('1'); }} /></div>
                <div className="column"><PriceButton
                    title = {this.multipleFloors ? "2 Etagen" : "1. Etage"}
                    logo={twoFloorLogo}
                    content="two_floors"
                    active={floors === '2'}
                    onclick={() => { this.update('2'); }} /></div>
                <div className="column"><PriceButton
                    title = {this.multipleFloors ? "3 Etagen oder mehr" : "2. Etage oder höher"}
                    logo={threeMoreFloorLogo}
                    content="more_floors"
                    active={floors === '3'}
                    onclick={() => { this.update('3'); }} /></div>
            </div>
        );
    }

    update(floors) {
        this.updated([
            { key: 'floors', value: floors },
            { key: 'contentWidgetStep', value: 'alarmWindows' }
        ]);
    }
}

PriceWidgetAlarmFloors.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetAlarmFloors;
