import React from 'react';
import PropTypes from "prop-types"
import PriceButton from '../PriceButton';
import AbstractPriceWidget from '../AbstractPriceWidget';

import panicLogo from '../../../images/PriceCalculator/106 Panic Button AA.svg';
import dontKnowLogo from '../../../images/PriceCalculator/000 Weiss nicht AA.svg';
import noLogo from '../../../images/PriceCalculator/000 Nein AA.svg';

export class PriceWidgetAlarmPanicButton extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        let { panic_button } = this.prices;
        this.progress = 80;
        this.cssClass = 'panic_button';
        if (this.isObjectTypeBusiness()) {
            this.question = 'Möchten Sie gerne einen Überfallalarmschalter installieren lassen?';
        } else {
            this.question = 'Möchten Sie gerne einen Überfallalarmschalter installieren lassen (z.B. im Schlafzimmer)?';

        }
        this.setEventName('PanicButton');
        if (panic_button !== null) {
            this.updated([
                { key: 'nextButtonStep', value: 'alarmSmartphone' },
                { key: 'showNextButton', value: true }
            ]);
        }
    }

    elementButtons() {
        let { panic_button } = this.prices;

        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Ja"
                    logo={panicLogo}
                    content="yes"
                    active={panic_button === 'Ja'}
                    onclick={() => { this.update('Ja'); }} /></div>
                <div className="column"><PriceButton
                    title="Nein"
                    logo={noLogo}
                    content="no"
                    active={panic_button === 'Nein'}
                    onclick={() => { this.update('Nein'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo={dontKnowLogo}
                    content="dont_know"
                    active={panic_button === 'Weiß ich nicht'}
                    onclick={() => { this.update('Weiß ich nicht'); }} /></div>
            </div>
        );
    }

    update(panic_button) {
        this.updated([
            { key: 'panic_button', value: panic_button },
            { key: 'contentWidgetStep', value: 'alarmSmartphone' }
        ]);
    }
}

PriceWidgetAlarmPanicButton.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetAlarmPanicButton;
