import React from 'react';

import '../style/style.scss';
import navbarLogo from '../images/alarmanlagen-angebotsvergleich-white-on-white.svg';
import { Link } from 'gatsby';

const Navbar = () => {
	const initDate = Date.parse('03 Mar 2021 00:00:00 GMT');
	const now = Date.now();
	const hourDif = Math.trunc(Math.abs(now - initDate)/36e5);
	const amountOfInquiries = (14220 + hourDif).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return (
		<nav className="ma-navbar">
			<div className="level">
				<div className="level-left">
					<div className="level-item">
						<Link to="/">
							<figure class="image logo" hr>
								<img src={navbarLogo}/>
							</figure>
						</Link>
					</div>
				</div>
				<div className="level-right is-hidden-mobile">
					<div className="level-item">
						<div className="has-text-centered counter">
							<p>Bereits <strong>{amountOfInquiries}</strong><br/>Alarmanlagen-Anfragen gestellt</p>
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
};

export default Navbar;
