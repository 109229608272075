import React from 'react'
import { Link } from 'gatsby'
import '../style/style.scss'

const Footer = () => (

  <footer className="footer columns">
    <div className="content column has-text-centered">
      <p>
        <Link to="/agb/">AGB</Link> | <Link to="/datenschutz/">Datenschutz</Link> | <Link to="/impressum/">Impressum</Link>
      </p>
      <p>
        © {(new Date()).getFullYear()} alarmanlagen-angebotsvergleich.de - Alle Rechte vorbehalten.
      </p>
    </div>
  </footer>
)

export default Footer
