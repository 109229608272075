export default class GoogleAnalytics {

    sendGTMEvent(event, values) {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            var payload={
                'event': event,
              };
            Object.assign(payload, values);
            window.dataLayer.push(payload);
        }
    }

    sendEvent(eventCategory, eventAction, eventLabel) {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({'event': eventAction,
                'send_to': process.env.GOOGLE_ANALYTICS_TRACKING_ID,
                'event_category': eventCategory,
                'event_label': eventLabel
              });
        }
    }

    sendView() {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            originalLocation: document.location.protocol + '//' +
                                document.location.hostname +
                                document.location.pathname +
                                document.location.search
            });
        }
    }
}
