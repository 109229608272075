import React from 'react';

const PriceVorteile = ({ name, mainStyle, subStyle }) => {
    return (
        <div>
            <div className={'columns ' + name + ' ' + mainStyle}>
                <div className={'column ' + name + '-left ' + subStyle}>
                    <i className="fas fa-check"></i> <span style={{fontSize: '0.9rem'}}>Kostenlos & unverbindlich</span>
                </div>
                <div className={'column ' + name + '-middle ' + subStyle}>
                    <i className="fas fa-check"></i> <span style={{fontSize: '0.9rem'}}>Einfach & sicher</span>
                </div>
                <div className={'column ' + name + '-right ' + subStyle}>
                    <i className="fas fa-check"></i> <span style={{fontSize: '0.9rem'}}>Bis zu 3 Angebote</span>
                </div>
            </div>
        </div>
    );
}

export default PriceVorteile;
