import React from 'react';
import PriceButton from '../PriceButton';
import PropTypes from "prop-types";
import GoogleAnalytics from '../../../helpers/GoogleAnalytics';
import AbstractPriceWidget from '../AbstractPriceWidget';
import { Haus, Wohnung, Gewerbe, Buero } from '../elements/Constants';

import GewerbeLogo from '../../../images/PriceCalculator/001 Gewerbe AA.svg';
import HausLogo from '../../../images/PriceCalculator/001 Haus AA.svg';
import WohnungLogo from '../../../images/PriceCalculator/001 Wohnung AA.svg';
import BueroLogo from '../../../images/PriceCalculator/001 Buero AA.svg';

class PriceStartWidgetAlarm extends AbstractPriceWidget {
    constructor(props) {
        super(props);

        if (this.prices.objectTypeWidget != null) {
            this.updated([
                { key: 'nextButtonStep', value: ''  },
                { key: 'showNextButton', value: true }
            ]);
        }
        this.progress = 15;
        this.eventName = 'calculator/a/PropertyType';
        this.nextStep = 'alarmFloors'
        this.cssClass = 'main';
        this.question = 'Welche Immobilie möchten Sie sichern?';
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendEvent('preisrechnerPriceStartWidgetAlarm', 'reached', 'question 1');
    }

    elementButtons() {
        let { objectType } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Haus"
                    logo={HausLogo}
                    content="house"
                    active={objectType === Haus}
                    onclick={() => { this.update('house', Haus); }} /></div>
                <div className="column"><PriceButton 
                    title="Wohnung"
                    logo={WohnungLogo}
                    content="flat"
                    active={objectType === Wohnung}
                    onclick={() => { this.update('flat', Wohnung); }} /></div>
                <div className="column"><PriceButton
                    title="Gewerbe"
                    logo={GewerbeLogo}
                    content="business"
                    active={objectType === Gewerbe}
                    onclick={() => { this.update('business', Gewerbe); }} /></div>
                <div className="column"><PriceButton
                    title="Büro"
                    logo={BueroLogo}
                    content="office"
                    active={objectType === Buero}
                    onclick={() => { this.update('office', Buero); }} /></div>
            </div>
        )
    }

    update (objectTypeWidget, objectType) {
        this.updated([
            { key: 'objectType', value: objectType },
            { key: 'objectTypeWidget', value: objectTypeWidget },
            { key: 'contentWidgetStep', value: this.nextStep }
        ]);
    }
}

PriceStartWidgetAlarm.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceStartWidgetAlarm;
