import React from 'react';

import '../style/style.scss';
import Helmet from './helmet';
import Footer from './footer';
import Navbar from './navbar';
import DefaultHero from './DefaultHero';

const Layout = ({ children }) => (
	<div>
		<Helmet />
		<Navbar />
		{children}
		<Footer />
	</div>
);

export default Layout;
