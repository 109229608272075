import React from 'react';
import PriceCalculator from './PriceCalculator/PriceCalculator';
import Cookies from 'js-cookie';
import { Alarmanlage } from './PriceCalculator/elements/Constants';
import bg from '../images/alarmanlagen-angebotsvergleich-desktop.jpg';
export default class DefaultHero extends React.Component {

    render() {
        let title = 'Alarmanlage Vergleichen';
        let image = bg;
        let image768 = bg;
        let image1024 = bg;
        let image1216 = bg;
        let image1408 = bg;
        let product = Alarmanlage;
        let defaultCssClass = '';
        return (
            <div className={"hero is-light default-hero " + defaultCssClass }>
                <div className="hero-image">
                    <picture>
                        <source media="(max-width:768px)" srcSet={image768} />
                        <source media="(max-width:1024px)" srcSet={image1024} />
                        <source media="(max-width:1216px)" srcSet={image1216} />
                        <source media="(min-width:1217px)" srcSet={image1408} />
                        <img src={image} style={{width:'auto'}} alt={title} title={title}/>
                    </picture>
                </div>
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="hero-box">
                            <PriceCalculator product={product} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
