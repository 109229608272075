import React from 'react';
import PriceButtonCheckBox from '../PriceButtonCheckBox';
import PropTypes from "prop-types";
import AbstractPriceWidget from '../AbstractPriceWidget';
import { Videoueberwachung, Tuerschloss, Brand, Haus, Wohnung } from '../elements/Constants';

import VideoLogo from '../../../images/PriceCalculator/108 Videoanlage AA.svg';
import DoorLockLogo from '../../../images/PriceCalculator/108 Door Lock AA.svg';
import BrandLogo from '../../../images/PriceCalculator/108 Fire AA.svg';

class PriceWidgetAlarmCrossSelling extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        // this.nextStep = 'kfw';
        // if (this.isObjectTypeBusiness()){
        //     this.nextStep = 'checkout';
        // }
        this.updated([
            { key: 'showNextButton', value: true },
            { key: 'nextButtonStep', value: 'postalCode' },
            { key: 'showSkip', value: true }
        ]);
        this.progress = 90;
        this.setEventName('crossSelling');
        this.cssClass = 'crossSelling';
        this.questionHtml = '<h3>Haben Sie Interesse an folgenden Erweiterungen?</h3><p>Mehrfachauswahl möglich</p>';
    }

    elementButtons() {
        let { crossSelling, objectType } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButtonCheckBox
                    title="Videoanlage"
                    logo={VideoLogo}
                    content={Videoueberwachung}
                    active={crossSelling.includes(Videoueberwachung)}
                    onclick={() => { this.update(crossSelling, Videoueberwachung); }} /></div>
                <div className="column"><PriceButtonCheckBox
                    title={(objectType === Haus ||  objectType === Wohnung) ? 'Elektronische Türschlösser ' : 'Schließanlage '}
                    logo={DoorLockLogo}
                    content={Tuerschloss}
                    active={crossSelling.includes(Tuerschloss)}
                    onclick={() => { this.update(crossSelling, Tuerschloss); }} /></div>
                <div className="column"><PriceButtonCheckBox
                    title="Brandmeldeanlage"
                    logo={BrandLogo}
                    content={Brand}
                    active={crossSelling.includes(Brand)}
                    onclick={() => { this.update(crossSelling, Brand); }} /></div>
            </div>
        )
    }

    update(crossSelling, product) {
        const index = crossSelling.indexOf(product);
        if (index > -1) {
            crossSelling.splice(index, 1);
        } else {
            crossSelling.push(product);
        }
        this.updated([
            { key: 'crossSelling', value: crossSelling }
        ]);
    }
}

PriceWidgetAlarmCrossSelling.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetAlarmCrossSelling;
