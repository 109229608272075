import React from 'react';
import PropTypes from "prop-types";
import PriceVorteile from './elements/PriceVorteile';
import GoogleAnalytics from '../../helpers/GoogleAnalytics';
import moment from 'moment';
import { Brand, Tuerschloss, Alarmanlage, Videoueberwachung, BeidesInteressant, WeissNicht } from './elements/Constants';
import Fade from 'react-reveal/Fade';
import { Link } from "gatsby";

export class PriceCheckout extends React.Component {

    constructor(props) {
        super(props);
        this.updated = this.props.updated;
        this.updated([ { key: 'showBottomButtons', value: false }, { key: 'showSkip', value: false }]);
        this.sending = false;
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
        if (this.props.prices.securitySystem === Brand) {
            this.message = "Gute Nachricht! Eine erste Preisindikation wird für Sie ermittelt.";
        } else {
            this.message = "Die Angebote für Ihre Anfrage werden erstellt.";
        }
    }

    componentDidMount() {
        let ga = new GoogleAnalytics();
        ga.sendEvent('preisrechner', 'reached', 'checkout');
        this.sendEvent();
    }

    sendEvent() {
        let ga = new GoogleAnalytics();
        var prefix='unknown';
        if (this.props.prices.securitySystem === Brand) {
            prefix='f';
        } else if (this.props.prices.securitySystem === Tuerschloss) {
            prefix='d';
        } else if (this.props.prices.securitySystem === Alarmanlage) {
            prefix='a';
        } else if (this.props.prices.securitySystem === Videoueberwachung) {
            prefix='v';
        } else if (this.props.prices.securitySystem === BeidesInteressant || this.props.prices.securitySystem === WeissNicht) {
            prefix='av';
        }
        ga.sendGTMEvent('calculator/'+prefix+'/Checkout');
    }

    vorteile(name, mainStyle, subStyle) {
        return (
            <div className={'columns ' + name + ' ' + mainStyle}>
                <div className={'column is-vertical-center ' + name + '-left ' + subStyle}>
                    <i className="fas fa-check"></i> Kostenlose Sicherheitsberatung
                </div>
                <div className={'column is-vertical-center ' + name + '-middle ' + subStyle}>
                    <i className="fas fa-check"></i> Alles aus einer Hand
                </div>
                <div className={'column is-vertical-center ' + name + '-right ' + subStyle}>
                    <i className="fas fa-check"></i> Systeme vom Testsieger
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="content checkout" key="checkout">
                <Fade right>
                    <div className="columns checkout-top is-mobile">
                        <div className="column is-narrow checkout-top-left has-text-centered">
                            <i className="fas fa-check"></i>
                        </div>
                        <div className="column is-vertical-center checkout-top-middle">
                            <span className='is-size-4-tablet'>{this.message}</span>
                            {/* <div className="arrow-down">
                            </div> */}
                        </div>
                    </div>
                    <div className="columns checkout-middle">
                    <div className="column">
                        <div className="columns">
                            <div className="column has-text-centered checkout-middle-top">
                                Wer soll die <strong>kostenlosen</strong> Angebote erhalten?
                            </div>
                        </div>
                        <form id="checkoutForm" onSubmit={ (e) => {
                                e.preventDefault();
                                if (e.target.checkValidity()) {
                                    this.sending=true;
                                    this.updated([
                                        { key: 'generatedTime', value: moment().utc().format("YYYY-MM-DDTHH:mm:ss") },
                                        { key: 'sendEmail', value: true }
                                    ]);
                                }
                            }
                        }>
                            <div className="columns">
                                <div className="column checkout-middle-left">
                                    <div className="columns">
                                        <div className="column has-text-left">
                                            <label className="label-container">Herr
                                                <input type="radio" required name="salutation" value="Herr" onChange={this.update.bind(this)}/>
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="label-container">Frau
                                                <input type="radio" required name="salutation" value="Frau" onChange={this.update.bind(this)}/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column vorname">
                                            <input className="input" name="firstName" required placeholder="Vorname" onChange={this.update.bind(this)}/>
                                        </div>
                                        <div className="column nachname">
                                            <input className="input" name="lastName" required placeholder="Nachname" onChange={this.update.bind(this)}/>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column">
                                            <input className="input" type="email" required name="email" placeholder="E-Mail" onChange={this.update.bind(this)}/>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column">
                                            <input className="input" type="tel" required name="phone" placeholder="Telefon" onChange={this.update.bind(this)}/>
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column datenschutz">
                                        <i className="fas fa-lock"></i>Die Sicherheit Ihrer Daten ist uns wichtig! Ihre Daten werden streng vertraulich behandelt und nicht an Dritte weitergegeben.
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column submit">
                                            <button id="checkoutSubmit" className={'button preisrechner-btn preisrechner-btn-primary checkout-button' + (this.sending ? ' is-loading' : '')}>
                                                Preis berechnen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                </Fade>
                <PriceVorteile name="checkout-bottom vorteil" mainStyle="only-desktop-flex" subStyle="has-text-centered"/>
            </div>
        );
    }

    update(event) {
        this.updated([
            { key: event.target.name, value: event.target.value }
        ]);
    }
}

PriceCheckout.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceCheckout;
