import React from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import AbstractPriceWidget from '../AbstractPriceWidget';

export class PriceWidgetAlarmWindows extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 55;
        let objectTypeWidget = this.props.prices.objectTypeWidget;
        this.multipleFloors = (objectTypeWidget === 'house' || objectTypeWidget === 'business') ? true : false
        if (this.multipleFloors) {
            this.question = 'Wie viele Fenster und Außentüren hat Ihr ' + (objectTypeWidget === 'house' ? 'Haus?' : 'Gewerbe?');
        } else {
            this.question = 'Wie viele Fenster und Außentüren hat ' +  (objectTypeWidget === 'office' ? 'Ihr Büro?' : 'Ihre Wohnung?');
        }
        this.cssClass = 'windows';
        this.setEventName('DoorsAndWindows');
        if (this.isObjectTypeBusiness()) {
            this.nextStep = 'alarmPanicButton';
        } else {
            this.nextStep = 'alarmPets';
        }
        let data = [
            { key: 'showNextButton', value: true },
            { key: 'nextButtonStep', value: this.nextStep }
        ];
        this.updated(data);
    }

    elementButtons() {
        var { windows } = this.prices;
        let elements = [];
        for(var i = 1; i <= 100; i++) {
            elements.push(<option key={i} value={i}>{i}</option>)
        }
        return (
            <div className="columns is-gapless-desktop is-centered">
                <div className="column price-range-text is-narrow">
                    <strong>Anzahl Fenster und Außentüren: </strong>
                </div>
                <div className="column price-range is-narrow">
                    <div className="select">
                        <select onChange={(event) => { this.updated([{ key: 'windows', value: event.target.value}]);}} defaultValue={windows}>
                            {elements}
                        </select>
                    </div>
                </div>
                <div className="column price-range is-narrow">
                    <button
                            className="button"
                            onClick={() => {
                                this.updated([
                                    { key: 'cctv_count', value: 'Weiß nicht' },
                                    { key: 'contentWidgetStep', value: this.nextStep }
                                ]);
                                }
                            }
                    >Weiß nicht</button>
                </div>
            </div>
        );
    }
}

PriceWidgetAlarmWindows.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetAlarmWindows;
