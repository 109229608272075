import React from "react";
import PriceWidgetThanks from "./PriceWidgetThanks";
import PriceCheckout from "./PriceCheckout";
import QueryString from "../../helpers/QueryString";
import PriceWidgetKfW from "./PriceWidgetKfW";
import PriceWidgetPostalCode from "./PriceWidgetPostalCode";
import PriceVorteile from "./elements/PriceVorteile";
import Cookies from "js-cookie";
import { isObject } from "util";

import {
  Videoueberwachung,
  Alarmanlage,
  Tuerschloss,
  Brand,
} from "./elements/Constants";
// alarm questions :
import PriceStartWidgetAlarm from "./AlarmWidgets/PriceStartWidgetAlarm";
import PriceWidgetAlarmFloors from "./AlarmWidgets/PriceWidgetAlarmFloors";
import PriceWidgetAlarmWindows from "./AlarmWidgets/PriceWidgetAlarmWindows";
import PriceWidgetAlarmPets from "./AlarmWidgets/PriceWidgetAlarmPets";
import PriceWidgetAlarmPanicButton from "./AlarmWidgets/PriceWidgetAlarmPanicButton";
import PriceWidgetAlarmSmartphone from "./AlarmWidgets/PriceWidgetAlarmSmartphone";
import PriceWidgetAlarmCrossSelling from "./AlarmWidgets/PriceWidgetAlarmCrossSelling";

require("es6-promise").polyfill();
require("isomorphic-fetch");

const pricesInitState = {
  objectType: null,
  contentWidgetStep: "main",
  floors: null,
  windows: 1,
  lastStep: ["main"],
  showButtons: true,
  showSkip: false,
  showNextButton: false,
  showPrevButton: false,
  showBottomButtons: true,
  nextButtonStep: null,
  securitySystem: null,
  windows_saver: null,
  panic_button: null,
  cctv: null,
  cctv_count: 1,
  cctv_type: null,
  cctv_resolution: null,
  smartphone: null,
  kfw: null,
  finance: null,
  pets: null,
  fireAlarmAlreadyEquiped: null,
  fireAlarmFloors: 1,
  fireAlarmRealEstateSize: null,
  fireAlarmRequirements: null,
  fireAlarmCarryProcess: null,
  crossSelling: [],
};

const valueElements = [
  "objectType",
  "securitySystem",
  "floors",
  "windows",
  "windows_saver",
  "panic_button",
  "pets",
  "kfw",
  "cctv",
  "cctv_count",
  "cctv_type",
  "cctv_resolution",
  "smartphone",
  "finance",
  "fireAlarmAlreadyEquiped",
  "fireAlarmFloors",
  "fireAlarmRealEstateSize",
  "fireAlarmRequirements",
  "fireAlarmCarryProcess",
];

const PriceCalculatorComponents = {
  kfw: PriceWidgetKfW,
  checkout: PriceCheckout,
  thanks: PriceWidgetThanks,
  startAlarm: PriceStartWidgetAlarm,
  alarmFloors: PriceWidgetAlarmFloors,
  alarmWindows: PriceWidgetAlarmWindows,
  alarmPets: PriceWidgetAlarmPets,
  alarmPanicButton: PriceWidgetAlarmPanicButton,
  alarmSmartphone: PriceWidgetAlarmSmartphone,
  alarmCrossSelling: PriceWidgetAlarmCrossSelling,
  postalCode: PriceWidgetPostalCode,
};

class PriceCalculator extends React.Component {
  constructor(props) {
    super(props);
    let prices = this.readState();

    if (this.isLastPage(prices)) {
      prices = this.resetState();
      this.updated([{ key: "contentWidgetStep", value: "thanks" }]);
    } else {
      prices = this.resetState(true);
    }
    this.state = prices;
  }

  componentWillMount() {
    switch (this.props.product) {
      case Alarmanlage:
        this.updated([
          { key: "lastStep", value: ["startAlarm"] },
          { key: "contentWidgetStep", value: "startAlarm" },
          { key: "securitySystem", value: Alarmanlage },
        ]);
        break;
      default:
        this.updated([
          { key: "lastStep", value: ["startAlarm"] },
          { key: "contentWidgetStep", value: "startAlarm" },
          { key: "securitySystem", value: Alarmanlage },
        ]);
        break;
    }
  }

  render() {
    let {
      lastStep,
      showNextButton,
      nextButtonStep,
      showBottomButtons,
      showButtons,
      showSkip,
    } = this.state;
    return (
      <div className="price-calculator-columns">
        <div
          className="box price-box is-half is-offset-one-quarter"
          style={{ overflow: "hidden" }}
        >
          <article className="media price-calculator">
            <div className="media-content">
              {getContent(this.state, this.updated.bind(this))}
            </div>
          </article>
          {showButtons && (
            <div
              className="columns is-vcentered price-footer"
              style={{
                display: showBottomButtons ? "flex" : "none",
                marginTop:
                  typeof window != "undefined" && window.innerWidth > 640
                    ? "20px"
                    : "0",
              }}
            >
              <div className="column is-narrow">
                <button
                  className="button button-left is-pulled-left"
                  disabled={!(lastStep.length > 1)}
                  onClick={() => {
                    lastStep.pop();
                    this.updated([{ key: "lastStep", value: lastStep }]);
                  }}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>
              </div>
              <div className="column"></div>
              {showSkip && (
                <div className="column is-narrow">
                  <button
                    className="button button-skip"
                    disabled={!(lastStep.length > 1)}
                    onClick={() => {
                      lastStep.pop();
                      this.updated([
                        { key: "contentWidgetStep", value: nextButtonStep },
                        { key: "showNextButton", value: false },
                      ]);
                    }}
                  >
                    Überspingen
                  </button>
                </div>
              )}
              <div className="column is-narrow">
                <button
                  className="button button-right"
                  disabled={!(showNextButton && nextButtonStep)}
                  onClick={() => {
                    this.updated([
                      { key: "contentWidgetStep", value: nextButtonStep },
                      { key: "showNextButton", value: false },
                    ]);
                  }}
                >
                  Weiter
                </button>
              </div>
            </div>
          )}
          {!showButtons && (
            <div
              className="columns price-footer"
              style={{ display: showBottomButtons ? "flex" : "none" }}
            ></div>
          )}
          {showBottomButtons && (
            <PriceVorteile name="vorteil" mainStyle="" subStyle="" />
          )}
        </div>
      </div>
    );
  }

  readState() {
    let prices = Cookies.getJSON("state");
    if (!isObject(prices)) {
      prices = pricesInitState;
    }

    return prices;
  }

  resetState(keepValues = false) {
    let resetedPrices = pricesInitState;
    if (keepValues) {
      let prices = this.readState();
      for (let i = 0; i < valueElements.length; i++) {
        resetedPrices[valueElements[i]] = prices[valueElements[i]];
      }
      Cookies.set("state", resetedPrices);
    } else {
      Cookies.remove("state");
    }

    return resetedPrices;
  }

  writeState(prices) {
    Cookies.set("state", prices);
    this.setState(prices);
  }

  updatePage(prices, newPage) {
    if (prices.lastStep.indexOf(newPage) === -1) {
      prices.lastStep.push(newPage);
    }

    return prices;
  }

  isLastPage(prices) {
    return prices.lastStep[prices.lastStep.length - 1] === "thanks";
  }

  updated(data) {
    let prices = this.readState();
    let contentWidgetStep = null;
    let sendEmail = false;
    data.forEach((element) => {
      prices[element.key] = element.value;
      switch (element.key) {
        case "contentWidgetStep":
          contentWidgetStep = element.value;
          break;
        case "sendEmail":
          sendEmail = element.value;
          break;
        default:
          break;
      }
    });
    if (contentWidgetStep !== null) {
      prices = this.updatePage(prices, contentWidgetStep);
    }
    this.writeState(Object.assign(this.readState(), prices));
    if (sendEmail) {
      this.sendEmail();
    }
  }

  sendEmail() {
    let prices = this.readState();
    let query = new QueryString();
    switch (prices.securitySystem) {
      case "Einbruch":
        prices.cctv_count = null;
        break;
      case "Video":
        prices.windows = null;
        break;
      default:
        break;
    }
    Object.keys(prices).forEach((key) => {
      if (prices[key] === null || prices[key] === "") {
        prices[key] = "ungefragt";
      }
    });
    let body = Object.assign(prices, query.getQuery());
    if (
      prices.firstName === "2c08d52c-ad14-11e8-98d0-529269fb1459" &&
      prices.lastName === "d48f9d07-46d4-41a3-8ce8-4e0351756950"
    ) {
      prices = this.updatePage(prices, "thanks");
      this.writeState(Object.assign(this.readState(), prices));
      this.resetState();
    } else {
      // fetch(process.env.GATSBY_MA24_API_URL + '/checkout', {
      fetch("https://api.meinalarm24.de" + "/checkout", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((response) => {
        prices = this.updatePage(prices, "thanks");
        this.writeState(Object.assign(this.readState(), prices));
        this.resetState();
      });
    }
  }
}

function getContent(prices, updated) {
  prices.contentWidgetStep = prices.lastStep[prices.lastStep.length - 1];
  if (prices.contentWidgetStep !== ("checkout" || "postalCode")) {
    scrollToTop();
  }
  return React.createElement(
    PriceCalculatorComponents[prices.contentWidgetStep],
    { prices, updated, key: prices.contentWidgetStep }
  );
}

function scrollToTop() {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
}

export default PriceCalculator;
