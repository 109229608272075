import React from 'react';
import PropTypes from "prop-types";
import GoogleAnalytics from '../../helpers/GoogleAnalytics';
import { Brand, Tuerschloss, Alarmanlage, Videoueberwachung, BeidesInteressant, WeissNicht } from './elements/Constants';
import Fade from 'react-reveal/Fade';

export class PriceWidgetThanks extends React.Component {

    constructor(props) {
        super(props);
        this.updated = this.props.updated;
        this.updated([
            { key: 'showBottomButtons', value: false },
            { key: 'showNextButton', value: false }
        ]);
    }

    componentDidMount() {
        let ga = new GoogleAnalytics();
        ga.sendEvent('preisrechner', 'reached', 'thank you');
        this.sendEvent()
    }

    sendEvent() {
        let ga = new GoogleAnalytics();
        var prefix='unknown';
        if (this.props.prices.securitySystem === Brand) {
            prefix='f';
        } else if (this.props.prices.securitySystem === Tuerschloss) {
            prefix='d';
        } else if (this.props.prices.securitySystem === Alarmanlage) {
            prefix='a';
        } else if (this.props.prices.securitySystem === Videoueberwachung) {
            prefix='v';
        } else if (this.props.prices.securitySystem === BeidesInteressant || this.props.prices.securitySystem === WeissNicht) {
            prefix='av';
        }
        ga.sendGTMEvent('calculator/'+prefix+'/Thankyou');
    }

    render() {
        return (
            <div className="thanks" key="thanks">
                <Fade right>
                    <div className="columns thanks-top is-mobile">
                        <div className="column is-narrow thanks-top-left has-text-centered">
                            <i className="fas fa-check"></i>
                        </div>
                        <div className="column is-vertical-center thanks-top-middle">
                            <span className='is-size-4-tablet'>Herzlichen Dank für Ihre Angebotsanfrage!</span>
                            {/* <div className="arrow-down">
                            </div> */}
                        </div>
                    </div>
                    <div className="content has-text-centered">
                        <p className="is-size-5"><strong>Wie geht es weiter?</strong></p>
                        <p className="is-size-5">Wir haben Ihre Anfrage an bis zu 3 passende Fachfirmen aus Ihrer Region weitergeleitet.</p>
                        <p className="is-size-5">Diese Fachfirmen werden sich für die maßgeschneiderte Angebotserstellung mit Ihnen in Verbindung setzen.</p>
                    </div>
                </Fade>
            </div>
        );
    }
}

PriceWidgetThanks.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetThanks;
